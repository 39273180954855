<template>
  <div class="linkMan mab">
    <div class="border" v-for="(item, index) in data" :key="index">

      <div class="title flex flex-between ">
        <div>企业联系人{{ index + 1 }}</div>
        <div class="active-color" v-if="index!=0" @click="handleRemove(index)">删除</div>
      </div>
      <div class="flex content flex-between">
        <div>
          <el-form-item
            label="联系人姓名："
            :prop="'lstCutomer.'+index+'.cstName'"
            :rules="[{ required: true, message: '联系人姓名不能为空' }]"
          >
            <el-input v-model="item.cstName"></el-input>
          </el-form-item>
          <el-form-item
            label="联系方式："
            :prop="'lstCutomer.'+index+'.tel'"
            :rules="[{ required: true, message: '联系方式不能为空' }]"
          >
            <el-input v-model.number="item.tel" :maxlength="11" :minlength="11"></el-input>
          </el-form-item>
        </div>
        <el-form-item
          label="联系人职务："
          :prop="'lstCutomer.'+index+'.position'"
          :rules="[{ required: true, message: '联系人职务不能为空' }]"
        >
          <el-input v-model="item.position"></el-input>
        </el-form-item>
      </div>
    </div>
    <div class="active-color border addBtn" @click="addLink">+添加联系人</div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
    },
  },
  methods: {
    addLink() {
      this.$emit("addLinkman", {
        name: "lstCutomer",
        data: {
          cstName: "",
          position: "",
          tel: "",
        },
      });
    },
    handleRemove(index){
      this.$emit('removeLinkman',{
        name:'lstCutomer',
        i:index
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.linkMan {
  .title {
    background: #f6f8fd;
    color: #374567;
    font-size: 14px;
    padding: 8px 24px;
    margin-bottom: 10px;
  }
  .content {
    padding: 16px 12px;
  }
  .border {
    margin-bottom: 20px;
  }
  .active-color{
    cursor:pointer;
  }
}
</style>